import React, { forwardRef, ReactNode, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";

import "./b2x-telia-heading.scss";

export const HeadingTag = {
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  H6: "h6",
} as const;

export const headingTags = Object.values(HeadingTag);

export type HeadingTagType = typeof HeadingTag[keyof typeof HeadingTag];

export const TitleVariant = {
  TITLE100: "title-100",
  TITLE200: "title-200",
  TITLE300: "title-300",
  TITLE400: "title-400",
  TITLE500: "title-500",
  TITLE600: "title-600",
  TITLE700: "title-700",
  SUBSECTION100: "subsection-100",
} as const;

export type TitleVariantType = typeof TitleVariant[keyof typeof TitleVariant];

export const DisplayVariant = {
  DISPLAY25: "display-25",
  DISPLAY50: "display-50",
  DISPLAY100: "display-100",
  DISPLAY200: "display-200",
} as const;

export type DisplayVariantType = typeof DisplayVariant[keyof typeof DisplayVariant];

type Props = React.HTMLProps<HTMLHeadingElement> & {
  children: ReactNode;
  tag: HeadingTagType;
  disableHyphenation?: boolean;
  variant?: TitleVariantType | DisplayVariantType;
  className?: string;
  isActive?: boolean;
  ["data-testid"]?: string;
};

const TeliaHeadingComponent = <T extends HTMLHeadingElement>(
  {
    children,
    tag,
    variant = TitleVariant.TITLE100,
    disableHyphenation = false,
    className = "",
    isActive,
    ...rest
  }: Props,
  ref: React.ForwardedRef<T>
) => {
  useEffect(() => {
    obsoleteWarning("b2x-telia-heading", "components-heading--docs");
  }, []);

  const DynamicTag = tag;
  const noHyphensClass = disableHyphenation ? "b2x-telia-heading--no-hyphens" : "";
  return (
    <DynamicTag
      ref={ref}
      className={`b2x-telia-heading b2x-telia-heading--${variant} ${noHyphensClass} ${className} ${
        isActive ? "active" : ""
      }`.trim()}
      {...rest}
    >
      {children}
    </DynamicTag>
  );
};

export const TeliaHeading = forwardRef(TeliaHeadingComponent);
TeliaHeading.displayName = "TeliaHeading";
